
#header1 {
  position: absolute;
  width: 162px;
  /* height:100%; */
}
#header1 .pro-sidebar {
  height: 95vh;
}
#header1 .subMenucss{
  margin-left: 1rem;
}
#header1 .subMenuItemCss{
  margin-left: -2rem;
}
#header1 .closemenu {
  color: var(--main-color);
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  /* top: 55px; */
  cursor: pointer;
}
#header1 .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header1 .pro-sidebar.collapsed {
  width: 25px;
  min-width: 25px;
}
#header1 .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 1px 0px rgba(0, 0, 0, 0.15);
}
#header1 .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header1 .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header1 .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
  margin-top: 3rem;
}
#header1 .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: -10px -23px;
  padding: 18px;

}
#header1 .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: white;
  /* color: # var(--main-color); */
  border-radius: 3px;
}
#header1 .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}
#header1 .pro-sidebar-inner .pro-sidebar-layout .pro-inner-item .active {
  background-color: #eaecfb;
  color:  var(--main-color);
  margin-right: 0px !important;
  padding-left: 5px !important;
  margin-left: 5px !important;
  padding-right: 0px !important;
  border-left: 5px solid;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color:  var(--main-color);
  font-family: 'Poppins';
  font-weight: 600;
  height: 30px;
  font-size: 11px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Poppins';
  font-weight: 600;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  content: none !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 20px;
  margin-top: -3.7rem;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--main-color);
  font-family: 'Poppins';
  font-size: 12px;
  text-transform: uppercase;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  margin-right: .5rem;
  border-color: var(--main-color);
  border-width: 0 3px 3px 0;

}
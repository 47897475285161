	body {
		background: rgb(245 245 245);
		font-family: 'Poppins';
		overflow-y: scroll;
	}

	.login-box {
		margin-top: 75px;
		height: auto;
		background: white;
		text-align: center;

	}

	.login-form {
		margin-top: 25px;
		text-align: left;
	}

	.logintextbox {
		background-color: white;
		border: none;
		border-bottom: 1px solid black;
		border-top: 0px;
		border-radius: 0px;
		outline: 0;
		margin-bottom: 20px;
		padding-left: 0px;
		color: black;
	}

	.loginpwdbox {
		background-color: white;
		border: none;
		border-bottom: 1px solid black;
		border-top: 0px;
		border-radius: 0px;

		outline: 0;
		padding-left: 0px;
		margin-bottom: 20px;
		color: black;
	}

	/* .form-group {
		margin-bottom: 40px;
		outline: 0px;
	} */

	.form-control:focus {
		border-color: inherit;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-bottom: 1px solid #c3c1c1;
		outline: 0;
		background-color: white;
		color: black;
	}

	input:focus {
		outline: none;
		box-shadow: 0 0 0;
	}

	.main-element {
		margin-left: 11rem;
	}

	.gradient-button {
		text-align: center;
		text-transform: uppercase;
		transition: 0.5s;
		background-size: 200% auto;
		color: #FFF;
		box-shadow: 0 0 20px #eee;
		border-radius: 10px;
		width: 100%;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
		transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
		cursor: pointer;
		display: inline-block;
		border-radius: 25px;
	}

	.gradient-button-1 {
		background-image: linear-gradient(to right, var(--main-color) 0%, var(--main-color) 51%, var(--main-color) 100%);
	}


	.main-content {
		width: 50em;
		box-shadow: 0 2px 5px rgba(0, 0, 0, .4);
		margin: 8em auto;
		display: flex;
	}

	@media screen and (max-width: 640px) {
		.main-content {
			width: 90%;
		}

		.company__info {
			display: none;
		}
	}

	@media screen and (min-width: 642px) and (max-width:800px) {
		.main-content {
			width: 70%;
		}
	}

	.login_form {
		background-color: #fff;
	}

	.forgot-psw {
		font-size: small;
		text-align: center;
		padding: 1rem;
	}

	.version {
		font-size: small;
		text-align: center;

	}

	.unmatchedimg {
		width: 200px;
		height: 150px;
		position: relative;
		display: inline-block;
		overflow: hidden;
		margin: 0;
	}

	.unmatchedimg img {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		transform: translate(-50%, -50%);
	}


	/* ----------thank you page----------- */

	.thank-you {
		text-align: center;
	}

	.logo-thankyou {
		width: 35%;
	}

	.heading-thankyou {
		color: #d80a0a;
		font-weight: 600;
		letter-spacing: 2px;
	}

	.contant-page {
		background: white;
	}

	.gradient-button-2 {
		background: var(--main-color);
		color: white;
		border-radius: 50px;
	}

	.thankyou-btn {
		padding: 1rem;
	}

	.box {
		margin-right: 1rem;
	}

	.cobtextbox {
		background-color: #f1f1f1;
		border: none;
		border-bottom: 3px solid #8e8c8c;
		;
		border-top: 0px;
		border-radius: 0px;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
		outline: 0;
		margin-bottom: 20px;
		padding-left: 0px;
		color: black;
	}

	.secondtab {
		background: white;
	}

	.confirmationbox {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}

	.font-size-1 {
		font-size: 13px;
	}

	input[type="checkbox"]:checked::after {
		position: absolute;
		content: "";
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 1px;
		/* background-color:  var(--main-color); */
		font-size: 14px !important;
		/* color: #FFBC00!important; */
		/* box-shadow: 0px 0px 0px 1px white inset; */
	}

	.checkbox-primary input[type="checkbox"]:checked+label::before,
	.checkbox-primary input[type="radio"]:checked+label::before {
		background-color: #44a3db;
		border-color: #44a3db;
	}

	input[type="checkbox"]:disabled {
		outline: 2px solid darkgray;
		cursor: not-allowed;
		box-shadow: 0px 0px 0px 1px darkgray inset;


	}

	input[type="checkbox"][readonly]::before {
		background: rgba(255, 255, 255, .5);
		cursor: not-allowed;
	}

	input[type="checkbox"][readonly]::after {
		background: rgba(255, 255, 255, .5);
		cursor: not-allowed;
	}

	input[type="checkbox"][readonly] {
		background: rgba(255, 255, 255, .5);
		cursor: not-allowed;
	}

	/* input[type="checkbox"]:disabled::after {
	background: rgba(255,255,255,.5);
	cursor:not-allowed;
	color:  var(--main-color) !important;
	
  } */


	.outer {
		border-radius: 45px;
		overflow: hidden;
		background-color: white;
		color: var(--main-color);
		padding: 0px 20px;
		border: solid 3px var(--main-color);
		width: 40%;
		margin-left: 25%;
		margin-bottom: 3rem;
		height: 7rem;
	}

	.tc-link {
		color: #44a4db;
	}

	.row.thank-you {
		margin-top: 107px;
	}

	.third {
		margin-top: 1rem;
		margin-left: 14px;
	}

	.infoicon {
		color: #44a3db;
		font-size: 18px;
	}

	.eldimgpages {
		margin-top: 3px;
	}

	input[type='radio']:checked:after {
		width: 15px;
		height: 15px;
		border-radius: 15px;
		top: -2px;
		left: -1px;
		position: relative;
		background-color: var(--main-color);
		content: '';
		display: inline-block;
		visibility: visible;
		border: 2px solid white;
	}

	.carrierCodes {
		font-size: 17px;
		font-weight: 400;
		font-family: 'Poppins';
	}

	.checkmark {
		font-family: arial;
		font-weight: bold;
		-ms-transform: scaleX(-1) rotate(-35deg);
		-webkit-transform: scaleX(-1) rotate(-35deg);
		transform: scaleX(-1) rotate(-35deg);
		color: darkblue;
		display: inline-block;
	}

	.checkmarkDouble {
		font-family: arial;
		font-weight: bold;
		-ms-transform: scaleX(-1) rotate(-35deg);
		-webkit-transform: scaleX(-1) rotate(-35deg);
		transform: scaleX(-1) rotate(-35deg);
		color: darkgreen;
		display: inline-block;
	}

	.checkmarkDoubleBelow {
		font-family: arial;
		font-weight: bold;
		-ms-transform: scaleX(-1) rotate(-35deg);
		-webkit-transform: scaleX(-1) rotate(-35deg);
		transform: scaleX(-1) rotate(-35deg);
		color: darkgreen;
		display: inline-block;
		clear: left;
	}

	.eldSearch {
		width: 50%;
		border-bottom: solid 1px;
		border-left: none;
		border-right: none;
		border-top: none;
		margin-top: 5px;
		background-color: #eaeef1;
		height: 30px;
	}

	.eldText {
		/* left: 67rem;
		top: 10px !important; */
		font-weight: 500 !important;
		color: black !important;
		font-style: normal !important;
		margin-left: .5rem;
	}

	@media only screen and (min-width: 500px) {
		.outer {
			border-radius: 45px;
			overflow: hidden;
			color: var(--main-color);
			padding: 0px 20px;
			border: solid 3px var(--main-color);
			width: 40%;
			margin-left: 30%;
			margin-top: 2rem;
			height: 7rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.welcome-header {
		margin-top: '6px';
		margin-left: 5%;
	}

	.wave-img {
		margin-top: 1rem;
	}

	@media only screen and (min-width: 750px) and (max-width: 1500px) {
		.outer {
			border-radius: 45px;
			overflow: hidden;
			color: var(--main-color);
			padding: 0px 20px;
			border: solid 3px var(--main-color);
			width: 70%;
			margin-left: 15%;
			margin-bottom: 3rem;
			height: 7rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@media only screen and (max-width: 500px) {
		.outer {
			border-radius: 45px;
			overflow: hidden;
			color: var(--main-color);
			border: solid 3px var(--main-color);
			width: 88%;
			margin-left: 6%;
			margin-bottom: 5rem;
			height: 7rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.welcome-header {
			margin-top: -46px;
			margin-left: 16%;
		}

		.welcomeCss {
			color: var(--main-color);
			font-size: small;
			font-family: 'Poppins';
		}
	}

	.progress-bar-container {
		height: 14px;
		/* width: 7vw; */
		background-color: var(--main-color);
		border-radius: 50px;
		margin: 14px auto 0 auto;
		margin-left: -1rem;
		margin-right: -2rem;
		z-index: 10;
		padding-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.labelCssForSearchWidget {
		display: inline-block;
		margin-left: 0.5rem;
		height: calc(1.5em + 0.75rem + 2px);
		width: 90%;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.tc-link {
		color: #44a4db;
	}

	.tc-link-text {
		font-family: 'Poppins';
		letter-spacing: 1px;
	}

	.onboard-btn {
		float: right;
		margin-right: 1rem;
	}

	.onboard-closebtn {
		float: right;
		margin-right: 1rem;
	}

	.widgetLogoCss {
		margin: 1.5rem;
		/* height: 60px; */
	}

	@media only screen and (max-width: 500px) {
		.labelCssForSearchWidget {
			display: inline-block;
			margin-left: 0.5rem;
			width: 85%;
		}

		.responsive-row {
			margin: 10px;
		}
	}

	.omniVLV {
		width: -webkit-fill-available !important;
	}

	.row-label {
		margin-left: 0px;
	}

	.in-col {
		padding-top: 10px;
	}
/* @import 'App.css'; */

.headerContainer {
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  padding-bottom: 16px;
}

.headerAction {
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  height: 2rem;
  width: auto;
}

/* .headerContainer {
  grid-template-columns: '1fr auto';
  grid-gap: '12rem';
  padding: '5px 0px 20px 0px';
  background: 'red'
}
.headerAction {
  grid-template-columns : 'auto auto';
  grid-gap: '1rem';
  height: '2rem';
  width: 'auto'
} */
.selectionBg {
  background-color: 'secondary';
}
.tableHeaderBarContainer {
  grid-template-columns: 1fr 25rem;
  align-items: center;
  padding: 12px;
  background-color: aqua;
}
.tableHeaderBarContainer .filterBarContainer {
  margin-left: auto;
  background-color: aqua;

}
.tableHeaderBarContainer .filterBarContainer .filterBarButton {
  border-radius: 50% !important;
  min-width: 0px !important;
  width: 35px;
  height: 35px;
  background-color: aqua;

}
.tableHeaderBarContainer .filterBarContainer .filterBarButton span {
  margin: inherit !important;
  background-color: aqua;

}
.tableHeaderBarContainer .filterBarContainer .rowSelectionContainer {
  grid-template-columns: 1fr 3rem 3rem 3rem;
  align-items: center;
  grid-gap: 1rem;
  background-color: aqua;

}

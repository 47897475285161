.mapboxgl-popup-content{
    background-color: #FFFFFF !important;
    border-radius: 6px;
    border: 1px solid #2184f7;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.mapboxgl-popup-content .map-popup-label1{    
    color: #000000;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
    border-top-color: #FFFFFF !important;
}
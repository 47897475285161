:root {
  --main-color: #f5a623;
  --secondary-color: #24c0f5;
}

html,
body {
  /* background-color: white; */
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font-size: 12px;
}

.bg-light {
  background-color: white !important;
  border-bottom: 1px solid lightgray !important;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 3px -6px #222;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

.masterhead {
  height: 100vh;
  min-height: 500px;
  background-image: url("login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.masterhead form {
  background-color: #fff;
  padding: 50px 50px;
  border-radius: 4px;
}

.masterhead form .login-logo {
  margin-bottom: 40px;
}

.masterhead .web-site {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 13px;
  color: #000;
}

#root,
.wrapper {
  height: 100%;
  /* background-color: #7470701c; */
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /*background: url('../images/loader.svg') 50% 50% no-repeat rgb(0, 0, 0.2);*/
  opacity: 0.9;
}

.maploader {
  position: fixed;
  text-align: center;
  padding: 1em;
  top: 50%;
  left: 60%;
  margin: 0 auto 1em;
  z-index: 9999;
}

.pos-rel {
  position: relative;
}

.flex-left-auto {
  margin-left: auto;
}

h4.page-title {
  margin: 0;
  padding: 5px 0px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

header .navbar-nav {
  padding: 0;
  height: 48px;
}

header .navbar-nav li {
  margin-right: 2px;
}

header .navbar-nav>li>a.nav-link {
  padding: 0 15px !important;
  height: 48px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 600;
}

header .navbar-nav>li>a.nav-link.active,
header .navbar-nav>li>a.nav-link:hover {
  background-color: var(--main-color);
  color: white !important;
}

header .navbar-nav>li>a>span {
  width: 100%;
  display: block;
  height: 50%;
}

header .navbar-nav>li>a>span>i {
  font-size: 22px;
  line-height: 1.5;
}

header .navbar-nav>li>a>span:last-child {
  line-height: 40px;
}

header .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3rem;
}

.btn-primary1 {
  color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
  outline: 0;
  /* border-radius: 50px; */
  font-size: 12px;
  font-family: 'Poppins';
  letter-spacing: 1px;
  font-family: 'Poppins';
}

.searchTextCss {
  font-size: 14px;
  font-family: 'Poppins';
}

.btn-primary2 {
  color: black;
  background-color: #daf8e2;
  box-shadow: none;
  outline: 0;
  /* border-radius: 50px; */
  font-size: 12px;
  font-family: 'Poppins';
  letter-spacing: 1px;
}

.btn-primary3 {
  color: black;
  background-color: #c7dcf5;
  box-shadow: none;
  outline: 0;
  /* border-radius: 50px; */
  font-size: 12px;
  font-family: 'Poppins';
  letter-spacing: 1px;
}

.btn-primary,
/* .btn-primary:hover, */
.btn-primary:active,
.btn-primary:focus {
  font-family: 'Poppins';
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
  font-family: 'Poppins';
  letter-spacing: 1px;
}

.btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.eldVendorNameCss {
  color: var(--main-color);
  background: ghostwhite;
  font-weight: 600;
  /* border-top: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6; */
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 15%);
  ;
  padding: .75rem;
}

.nav-tabs .nav-link {
  border: 0;
}

article.main-nav {
  padding: 0px 15px;
}

.main-nav .nav-tabs {
  border-bottom: 2px solid var(--main-color);
  height: 32px;
}

.main-nav .nav-tabs>.nav-item {
  height: 30px;
  margin-bottom: 0;
}

.main-nav .nav-tabs>.nav-item>a {
  height: 100%;
  background-color: #e9ecef;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 11px;
}

.main-nav .nav-tabs>.nav-item>a.active,
.main-nav .nav-tabs>.nav-item>a:hover {
  background-color: var(--main-color);
  color: #fff;
}

/*.accordion > .card .card-header{
	margin-bottom: 0;
}*/
.card {
  border-radius: 0;
  border: 0;
}

.card-header {
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0px;
  margin-bottom: 0;
}

.card-body {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.row.trim-margin {
  margin: 0;
}

form.tg-forms input[type="text"],
form.tg-forms input[type="password"],
form.tg-forms input[type="number"],
form.tg-forms input[type="date"],
form.tg-forms select {
  outline: 0;
  padding: 0px 5px;
  height: 26px;
  line-height: 26px;
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  box-shadow: none;
  border-radius: 2px;
  font-size: 13px;
  color: #000;
  border-radius: 1px !important;
}

form.tg-forms .form-group-btns {
  text-align: right;
}

form.tg-forms .form-group-btns>a {
  margin-left: 10px;
}

form.tg-forms label {
  margin-bottom: 2px;
}

.control-custom,
select.control-custom {
  width: 100%;
  height: 28px;
  padding: 1px 10px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  outline: 0;
  border: 1px solid #d5d5d5;
  box-shadow: none;
  border-radius: 1px !important;
  transition: all 0.3s ease-in-out;
}

.control-custom:focus {
  outline: 0;
  box-shadow: none;
  background-color: #e1e1e1;
  border: 1px solid #b7b6b6;
}

.label-custom {
  text-align: right;
}

.label-custom>span {
  padding-right: 15px;
  line-height: 30px;
}

.form-icon {
  position: relative;
}

.form-icon>span {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #888;
}

.box-wrap {
  height: calc(100% - 92px);
  padding: 0px 0px;
}

.stay-vertical {
  display: flex;
  width: 100%;
  height: 200px;
}

.stay-vertical>div {
  margin: auto;
  text-align: center;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.loggedin-toggle {
  border: 0;
  background-color: none;
  text-align: right;
  cursor: pointer;
}

.loggedin-toggle>span {
  display: block;
}

.loggedin-toggle>span:nth-child(1) {
  font-size: 13px;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

.loggedin-toggle>span:nth-child(2) {
  font-size: 11px;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

.loggedin-toggle>span:nth-child(3) {
  font-size: 13px;
  height: 100%;
}

.loggedin-toggle::after {
  display: none;
}

/*.dashboard{
	height: calc(100% - 80px);
	padding: 0px 15px;
}*/
.dashboard-data {
  height: calc(100% - 15px);
  /* margin-top: 5px; */
}

.dashboard-data>.row,
.dashboard-data>.row>[class^="col"] {
  height: 100%;
}

.dashboard-data .map {
  display: block;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: #f2efe9;
}

.dashboard-data .tab-search {
  height: 30px;
}

.dashboard-data .tab-content {
  padding: 15px;
  border-radius: 0;
  border: 0;
  height: calc(100% - 300px);
  overflow-y: auto;
  overflow: auto;
  margin-bottom: 1rem;
}

.tab-content-instantcob {
  margin-top: 2.5rem;
}

.dashboard-data .truck-status .nav-tabs {
  border-bottom: 2px solid var(--main-color);
  height: 30px;
}

.dashboard-data .truck-status .nav-tabs>.nav-item {
  height: 28px;
  margin-bottom: 0;
}

.dashboard-data .truck-status .nav-tabs>.nav-item>a {
  height: 100%;
  background-color: #e9ecef;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}

.dashboard-data .truck-status .nav-tabs>.nav-item>a.active,
.dashboard-data .truck-status .nav-tabs>.nav-item>a:hover {
  background-color: var(--main-color);
  color: #fff;
}

.dashboard-data .trcuk-breadcrump {
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: #f7f7f7;
  border-top: 1px solid #eee;
  width: 100%;
}

.dashboard-data .trcuk-breadcrump>.row>[class^="col"] {
  padding: 0px 5px;
}

.dashboard-data .trcuk-breadcrump .form-group {
  margin-bottom: 5px;
}

.accordion .card-header {
  padding: 8px;
  background-color: #f0f0f0;
}

/*input css*/
.shadow-input {
  color: red !important;
  font-size: 1rem;
  font-weight: normal;
  display: block !important;
  padding-top: 5px !important
}

.accordion .card-header .form-check .form-check-input,
.accordion .card-header .form-check .form-check-label {
  cursor: pointer;
}

.accordion .card-header .form-check>span>i {
  font-size: 10px;
}

.accordion .card-body {
  background-color: #e8e8e8;
}

.accordion .card-body ul li span:first-child {
  width: 50px;
  font-size: 11px;
  color: #888;
  display: inline-block;
}

.accordion .card-body ul li span:last-child {
  font-size: 12px;
  color: #000;
  display: inline;
}

.ol-control.ol-hidden {
  display: none;
  width: 0;
  height: 0;
}

.ol-zoom {
  position: absolute;
  top: 10px;
}

.ol-zoom-in,
.ol-zoom-out {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin-right: 5px;
  color: #fff;
  font-weight: 700;
}

.table-data {
  padding: 15px;
  margin-bottom: 15px;
}

.table-hover tbody tr:hover {
  background-color: #EDF6FC;
  color: white;
}

.table-data .nav-tabs {
  height: 30px;
  border-bottom: none !important;
  /* margin-bottom: 10px; */
}

.table-data .nav-item {
  height: 28px;
  border: 0;
  margin: 0;
}

.table-data .nav-item .nav-link {
  height: 100%;
  padding: 2px 15px;
  /* background-color: #e9ecef; */
  line-height: 26px;
  color: #000;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 11px;
  font-family: 'Poppins';
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.table-data .nav-item .nav-link.active,
.table-data .nav-item .nav-link:hover {
  border-bottom: solid 2px var(--main-color);
  color: var(--main-color);
  font-family: 'Poppins';
  letter-spacing: 1px;
  font-weight: 600;
}

.table-data .nav-item .nav-link>i {
  font-size: 20px;
}

table.table>thead>tr>th {
  /* background-color: #eeeeee; */
  vertical-align: middle !important;
  border: 0;
  font-size: 12px;
  padding: 6px 4px;
  font-family: 'Poppins';
}

table.table>tbody>tr>td {
  vertical-align: middle;
  font-size: 13px;
  color: #594c4c;
  font-family: 'Poppins';
}

.ul-horizontal>li {
  display: inline-block;
  margin-left: 2px;
}

.modal-lg {
  max-width: 1100px;
}

.proofofdelivery {
  max-width: 400px;
}

.popup-tender {
  padding: 20px;
}

.strip-pad .form-row [class*="col-"] {
  padding: 0px 5px;
}

.strip-pad .form-row [class*="col-"]>.row {
  padding: 0px;
  margin: 0 0 5px;
}

.strip-pad .form-row [class*="col-"]>.row .form-label {
  display: block;
  line-height: 26px;
  text-align: right;
}

.pageLoader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(loader.svg) center no-repeat #0000001f;
}

.dropdown-menu {
  padding: 0;
  border-radius: 1px;
  border: 0;
}

.div-content {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.dropdown-menu .dropdown-item {
  background-color: var(--main-color);
  border: 0;
  color: black;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.dropdown-menu .dropdown-item:hover {
  background-color: var(--main-color);
  color: #fff;
}

.tabify>a {
  background-color: var(--main-color);
  border: 1px solid #000;
  min-height: 80px;
  border-radius: 2px;
  margin-bottom: 10px;
}

.tabify>a:active,
.tabify>a:hover {
  background-color: var(--main-color);
  border: 1px solid #000;
  min-height: 80px;
  border-radius: 2px;
  margin-bottom: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.report-nav {
  margin: 15px 0px;
}

.report-link {
  margin-top: 15px;
  position: relative;
  background-color: #fff;
  display: block;
  border-radius: 2px;
  padding: 10px;
  text-align: right;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.report-link:hover {
  text-decoration: none;
}

p {
  font-family: 'Poppins';
}

.report-link>h4 {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
}

.report-link>h5 {
  color: #000;
}

.report-link>span {
  position: absolute;
  top: -16px;
  left: 15px;
  height: 36px;
  width: 36px;
  background-color: var(--main-color);
  text-align: center;
  color: #000;
  border-radius: 2px;
  line-height: 36px;
  font-size: 18px;
  font-weight: 600;
}

/* jerusha update */
.highlight {
  /* background-color: #eeeeee; */
  border: 0;
  padding: 6px 4px;
}

.bulkupload_box {
  border-width: 1px;
  border-style: dashed;
}

.add_plus {
  color: var(--main-color);
  font-size: 20px;
}

/* sunitha update */
.nav-item-driver .nav-link-driver.active {
  background-color: rgba(175, 175, 175, 0.2);
  color: #000;
}

.nav-item-driver .nav-link-driver {
  height: 100%;
  padding: 5px 15px;
  background-color: #ece8e2;
  line-height: 26px;
  color: #000;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}

.driver-search {
  margin-top: 100px;
}

.driver-tab {
  margin-left: 25px;
  background-color: rgba(175, 175, 175, 0.2);
  color: #000;
}

.tt_form {
  float: right;
}

.search-box {
  position: relative;
  bottom: 3px;
}

.search-box-ul {
  position: absolute;
}

.search-box-li {
  position: relative;
  left: 306%;
}

.spinner-border {
  margin-top: 50px;
  color: var(--main-color);
}

/* pavithra update */
.deleteicon {
  font-size: 15px;
  padding-left: 50%;
  text-align: right;
  margin-bottom: 10px;
  color: var(--main-color);
  float: right;
}

/* diana update */
.doc-color {
  color: var(--main-color);
}

.file-drop-area {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 25px;
  border: 1px dashed #e9ecef;
  border-radius: 3px;
  transition: 0.2s;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.ico_circle {
  padding-top: 20px;
}

.table-data .nav-item .tab-color {
  background-color: #bdb8b3;
  color: #fff;
  height: 100%;
  line-height: 26px;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 6px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #eee;
  cursor: pointer;
}

.table-data .tab-tab {
  height: 30px;
  border-bottom: 2px solid #b1adaa !important;
  margin-bottom: 15px;
}

.table-data .nav-item .nav-link.active.tab {
  background-color: #afafaf;
  color: #fff;
  font-size: 10px;
  padding: 4px 15px;
  display: inline;
}

.table-data .nav-item .nav-link.tab {
  background-color: #d2cccc;
  color: #fff;
  font-size: 10px;
  padding: 4px 15px;
  display: inline;
  color: #000;
}

.no-gutters .p-20 {
  padding-left: 37px;
  padding-right: 37px;
}

/* shaji update */
.error {
  color: red;
  font-size: 12px !important;
}

.redux-toastr .toastr .close-toastr {
  font-size: 15px;
  height: 55%;
}

/* .redux-toastr .toastr {
	background-color: green;
} */

i[aria-expanded="true"] .icofont-circled-right {
  display: none;
}

i[aria-expanded="false"] .icofont-circled-down {
  display: none;
}

/* Openlayer map */
/* .ol-popup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "X";
  }
  .ol-attribution.ol-unselectable{
    display: none;
  } */
tbody+thead {
  display: none;
}

/*.dataTables_filter,.dataTables_paginate 
{
	float: right;
}*/
.dataTable {
  text-align: left;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_filter input {
  width: auto;
}

div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
  margin-left: 0.5rem;
}

div.dataTables_wrapper div.dataTables_length select,
div.dataTables_wrapper div.dataTables_length input {
  width: auto;
}

div.dataTables_wrapper div.dataTables_length.d-flex.flex-row label {
  margin-top: 1.2rem;
  margin-right: 1rem;
}

div.dataTables_wrapper div.dataTables_length.d-flex.flex-row .select-wrapper.mdb-select span,
div.dataTables_wrapper div.dataTables_length.d-flex.flex-row .select-wrapper.mdb-select .select-dropdown {
  margin-top: 1rem;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 400;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-pack: end;
  font-family: 'Poppins';
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item.active .page-link:focus {
  background-color: #4285f4;
  font-family: 'Poppins';
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: 'Poppins';
}

table.dataTable thead {
  cursor: pointer;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
  padding-right: 30px;
}

table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

table.dataTable thead th:after {
  right: 1em !important;
}

.form-control-inline {
  display: inline !important;
}

a.btn-icon {
  color: var(--main-color) !important;
  text-decoration: none;
}

.row-odd {
  padding: 10px 0;
  border: none;

  margin: 0 -11px;
}

.row-even {
  margin: 0 -11px;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.03);
}

.bg1 {
  background-color: rgba(0, 0, 0, 0.03);
}

a.btn-icon>i.icofont-minus-circle {
  display: block;
}

a.btn-icon>i.icofont-plus-circle {
  display: none;
}

a.collapsed>i.icofont-minus-circle {
  display: none;
}

a.collapsed>i.icofont-plus-circle {
  display: block;
}

.dropdown>a.arrow-less::after {
  display: none;
}

.all-table tbody td {
  vertical-align: middle !important;
  padding: 5px 0.75rem;
  background-color: #f8f8f8;
  border-bottom: 2px solid #e3e6e8;
  transition: all 0.2s ease-in-out;
  font-size: 25px;
  white-space: nowrap;
}

.all-table thead th {
  background-color: #afafaf;
  color: black;
  padding: 10px 0.75rem;
  font-weight: 1000;
  font-size: 30px;
  border-bottom: 2px solid #e3e6e8;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(227, 230, 232);
  vertical-align: middle !important;
  white-space: nowrap;
}

table {
  border-collapse: collapse;
}

table {
  border-collapse: separate;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border-spacing: 2px;
  font-variant: normal;
}

table {
  border-collapse: collapse;
}

label.form-check-label {
  position: relative;
  display: inline-block;
  /* padding: 0 0 0 18px; */
  font-size: 12px !important;
  font-family: 'Poppins';
  letter-spacing: 1px;
  height: 15px;
  line-height: 15px;
  cursor: pointer;
}

.form-check-label {
  margin-bottom: 0;
}

.card {
  border-radius: 0px !important;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.choosecarriertabnone {
  display: none;
}

.choosecarriertabshow {
  display: block;
}

::after,
::before {
  box-sizing: border-box;
}

/* .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  height: 30px;
} */

.imgLogo {
  max-width: 100%;
  max-height: 100%;
}

.TruckX-Div {
  margin-top: 1rem;
}

.eldLogo {
  max-width: 120px;
  /* max-height: 75px; */
  padding-right: 2rem;
  float: right;
}

.truckXDiv {
  display: flex;
  justify-content: flex-end;
  float: right;
  margin-left: 22rem;
  margin-top: 6rem;
  background-color: #3CC13B;
}

.eld-container {
  display: flex;
  flex-wrap: wrap;
  /* background-color: red; */
}

.eld-img-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landscape {
  height: 80px;
}

/* li {
    text-align: -webkit-match-parent;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.125);
}	  
		   */

.card {
  border-radius: 0px !important;
}

div#loadAssetOrderModalPopup .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

div#loadAssetOrderModalPopup .card-header {
  font-weight: 600;
  font-size: 14px;
}

div#loadAssetOrderModalPopup .text-center {
  text-align: center !important;
}

div#loadAssetOrderModalPopup .p-2 {
  padding: 0.5rem !important;
}

div#loadAssetOrderModalPopup .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

div#loadAssetOrderModalPopup {
  box-sizing: border-box;
  max-width: 5000px;
}

.inputwidthtextbox {
  width: 100%;
}

.styleaddclass {
  width: "25%";
  float: "left";
  color: "red";
}

fieldset {
  overflow: hidden;
}

.some-class {
  float: left;
  clear: none;
}

/* label {
			float: left;
			clear: none;
			
			padding: 2px 1em 0 0;
		  } */

/* input[type=radio],
		  input.radio {
			float: left;
			clear: none;
			margin: 0 10px 0 10px;
			margin-top: 6px;
		  } */

.eld-provider {
  margin-right: 10px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.table-striped tbody tr:nth-of-type(even) {
  display: none !important;
}

.mapboxgl-popup-content {
  width: 106%;
}

.dashboard-top-menu {
  padding: 7px 0;
}

.dashboard-top-menu button {
  margin: 0 5px;
  text-transform: uppercase;
}

.dashboard-top-menu button:first-child {
  margin-left: 0;
}

.dashboard-top-menu button:last-child {
  margin-right: 0;
}

.dashboard-top-menu button.btn-light {
  background-color: #e9ecef;
}

.menu-item {
  padding: 0 15px;
  margin: 5px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper>button {
  text-transform: uppercase;
}

.menu-item-wrapper>button.btn-light1 {
  background-color: #e9ecef;
}

/* .menu-item-wrapper.active {
			background-color: var(--main-color);
			border: var(--main-color);
		  } */
.menu-item.active {
  border: 1px #44a3db solid;
}

.scroll-menu-arrow {
  padding: 11px;
  cursor: pointer;
}

.scroll-menu-arrow.scroll-menu-arrow--disabled {
  /*opacity: 0; */
  color: #ddd;
}

.scroll-menu-arrow>div {
  font-size: 22px;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

th.sortable>span.order {
  width: 9px;
  height: 9px;
  float: initial;
}

th.sortable>span.order::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
  position: absolute;
  opacity: 0.2;
}

th.sortable>span.order::after {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
  position: absolute;
  opacity: 0.2;
}

th.sortable>span.react-bootstrap-table-sort-order::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
  float: initial;
  opacity: 1;
}

th.sortable>span.dropup::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd" !important;
  float: initial;
  opacity: 1;
}

.order {
  float: initial;
  margin-left: 5px;
}

span.react-bootstrap-table-sort-order {
  float: initial;
  margin-left: 10px;
}

th.sortable>span.dropup>span.caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.search-label {
  float: right;
}

.react-bootstrap-table-pagination-total {
  padding-left: 15px;
  font-family: 'Poppins';
}

.btn-export {
  margin-left: 5px;
  font-family: 'Poppins';
}

/* .mapboxgl-popup {
			top: 40px!important;
		} */

div.modal-map {
  height: 90%;
}

div.modal-map div.modal-content {
  height: 100%;
}

div.modal-map div.modal-content div.modal-body {
  overflow: hidden;
}

div.modal-map div.modal-content div.map-container {
  height: 100%;
}

.modal-title {
  font-family: 'Poppins';
  font-weight: 600;
  height: 30px;
  font-size: 15px;
}

.accstyleicon {
  position: absolute;
  margin-left: 8rem;
  top: 5px;
  color: #44a3db;
}

.icofont-star {
  font-size: 18px;
  background: var(--main-color);
}

form.tg-forms textarea {
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
}

.staricon {
  font-size: 15px;
  color: var(--main-color);
}

.staricon:hover {
  color: var(--main-color);
  text-decoration: none;
}

/* .table>thead:first-child>tr:first-child>th {
			border-top: 0;
			padding: 5px;
		} */

button#dropdownMenu2 {
  color: #fff;
  background: var(--main-color);
  border: var(--main-color);
}

#change-password {
  margin-top: 15px;
  margin-bottom: 0px;
}

th#actionloadpoints {
  padding-left: 30px;
}

.form-group.json-col.level2.rw-datetime-picker.rw-widget {
  width: 141px;
}

select.form-control.json-col.level2 {
  height: 36px;
}

input.form-control.json-col.level2 {
  height: 36px;
}

th#zipcodeloadpoints {
  padding-left: 39px;
}

th#citynameloadpoints {
  padding-left: 64px;
}

th#statenameloadpoints {
  padding-left: 58px;
}

th#streetnameloadpoints {
  padding-left: 25px;
}

th#instructionloadpoints {
  padding-left: 36px;
}

th#latesttimeloadpoints {
  padding-left: 30px;
}

th#latestimeloadpoints {
  padding-left: 31px;
}

.btn-secondary {
  color: #fff;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  font-family: 'Poppins';
}

/* 
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--main-color);
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-family: 'Poppins';
}

.page-item.active .page-link {
  font-family: 'Poppins';
  z-index: 1;
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.page-link:hover {
  z-index: 2;
  color: var(--main-color);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
} */

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem .85rem;
  line-height: 1.25;
  color: var(--main-color);
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-family: 'Poppins';
  border-radius: 50px;
  margin-left: 5px;
}

.page-item.active .page-link {
  font-family: 'Poppins';
  z-index: 1;
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
  border-radius: 50px;
  margin-left: 5px;
  padding: 0.5rem .85rem;
}

.page-item:first-child .page-link {
  border-radius: 100px;
  margin-left: 5px;
  padding: 0.5rem 1rem;
}

.page-item:last-child .page-link {
  border-radius: 100px;
  margin-left: 5px;
  padding: 0.5rem .85rem;
}

.page-link:hover {
  z-index: 2;
  color: var(--main-color);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
  border-radius: 50px;
  margin-left: 5px;
  padding: 0.5rem .85rem;
}

a {
  color: #0e0e0fc4;
  text-decoration: none;
  /* background-color: var(--main-color); */
}

.dropdown-menu .dropdown-item {
  background-color: #e9ecef;
  border: 0;
  color: #000;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

#lanehistoryclass {
  font-size: 21px;
  color: var(--main-color);
  background-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
  letter-spacing: 1px;
  font-family: 'Poppins';
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .table-responsive {
    display: table;
  }
}

@media screen and (max-width: 567px) {
  .strategy-table>thead>tr>th {
    position: relative;
  }
}

@media screen and (max-width: 567px) {
  .nav-tabs {
    margin-top: 1rem;
    border-bottom: 0px solid !important;
  }
}

@media screen and (max-width: 567px) {
  .tab-content {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 567px) {
  .no-gutters {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 567px) {
  #search-bar-0 {
    margin-top: 2rem;
  }
}

i.icofont-video {
  font-size: large;
}

div#map-container {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

/* a.btn_carrier_move.btn.btn-sm.btn-primary {
			width: 82px !important;
		} */
/* thead {
			text-align: center;
		} */

.table td {
  /* padding: 0.20rem !important;  */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.requiredfield {
  border-left: 3px solid var(--main-color) !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  letter-spacing: 1px;
  font-family: 'Poppins';
}

div#stopped_stats,
div#idle_stats,
div#active_stats {
  height: 200px;
  overflow-x: hidden;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 1px;
}

/* ::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
			border-radius: 10px;
		}
		 
		::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
		} */

.loader {
  border: 16px solid #f3f3f3;
  /* border-radius: 50%; */
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-confirm-alert-body {
  font-family: "sans-serif";
  font-weight: 100;
  font-size: 1rem;
  width: 550px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: var(--main-color);
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: var(--main-color);
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

/* .form-group.drivercityselectbox {
			display: none;
		}

		.form-group.cityclassinput {
			display: block;
		} */

.addcontactcontent {
  width: 550px !important;
}

ul#myTab {
  width: auto;
}

#searchicondashboard {
  width: auto;
}

.truck-status {
  padding: 7px;
}

/* .accordion .card-header .form-check > span{
			color: red;
		} */

#map_parent .icofont-circled-left,
#map_parent .icofont-circled-right {
  font-size: 22px;
}

#map_parent .horizontal-menu .menu-item-wrapper .menu-item {
  background-color: #e9ecef;
  color: #000;
}

#map_parent .horizontal-menu .menu-item-wrapper .menu-item.active {
  color: #fff;
}

.bg-color-1 {
  background-color: rgb(79, 129, 189);
  float: left;
  width: 100%;
  padding: 10px;
}

.bg-color-1 h5 {
  margin-bottom: 0px;
  color: #fff;
}

.eld_data {
  float: left;
  width: 100%;
}

.form-control[readonly] {
  background-color: unset;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: block;
  /* vertical-align: middle; */
  font-size: 16px;
  cursor: pointer;
  padding-left: 45px;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  padding: 2px;
  margin-left: 10px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 13px;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
  background: var(--main-color);
  box-shadow: inset 0px 0px 0px 4px #fff;
}

.radio-custom+.radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
  background: var(--main-color);
  box-shadow: inset 0px 0px 0px 4px #fff;
}

.radio-custom-label img {
  max-width: 100%;
  /* height: 50px;
			 width: 80%; */
}

.custom-textbox {
  width: 80%;
  display: inline-block;
  height: 50px !important;
}

/* .checkbox-custom-label img
		{   
			max-width: 100%;
			height: 50px;
			width: 50px;
		} */
.radio-custom-label img {
  max-width: 100%;
  /* height: 50px;
			width: 50%; */
}

.checkbox-custom-label:focus,
input.checkbox-custom {
  border: 0px;
  box-shadow: unset;
  outline: 0;
}

.font-size-1 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
}

hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1) !important;
}

.eld-class {
  padding-left: 2rem;
  padding-right: 5rem;
}

.labl {
  font-weight: 600;
}

.carrier {
  width: 100%;
  display: flex;
  align-items: center;
}

.onboard-title {
  margin-bottom: 0px !important;
}

.nav-border {
  border-bottom: 3px solid lightgray;
}

.p-t-5 {
  padding-top: 15px;
}

/* .onboard-class{
			height: 100%;
		} */
::-webkit-scrollbar {
  width: unset;
}

.pos-rel {
  position: relative;
  float: left;
  width: 100%;
}

.pos-abs {
  position: absolute;
  top: 10px;
  right: 10px;
}

#namanyay-search-box {
  background: #eee;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border: 0 none;
  width: 200px;
}

#namanyay-search-btn {
  background: #0099ff;
  color: white;
  padding: 12px 20px;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
  border: 0 none;
  font-weight: bold;
}

.ti-search:before {
  content: "\e610";
}

.border-1 {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.radiopopup {
  display: none;
}

.footer {
  padding: 50px 0 !important;
  background: #000;
}

.footer .footer-lbl {
  color: #fff;
  margin-bottom: 15px;
}

.footer .footer-adress,
.footer .footer-phones,
.footer .footer-email {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
}

.header-section {
  background: #fff;
  padding: 5px 0;
  border-bottom: 2px solid #e8e8e8;
}

.carrier-board {
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.carrier-board .form-control {
  height: 35px !important;
}

.truck-font {
  font-size: 20px;
  margin-bottom: 10px;
}

.board-font {
  margin-bottom: 20px;
  font-size: 13px;
}

.eld-height .border-1 {
  height: 85px;
}

.d-table {
  top: 15rem;
}

.mt-10 {
  margin-top: 10px;
}

.thank-page {
  height: 100% !important;
  background-color: #f0f0f0;
}

.thank-text {
  font-size: 14px !important;
}

.thank-cred {
  font-size: 20px !important;
  margin-top: 10px;
}

a#optinoptoutpage {
  margin-bottom: -26px;
}

#user_nav .homelabel {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

li.searchicon {
  margin-top: -6px;
}

.shipperLaneTable {
  text-align: center;
}

.all-table thead th {
  background-color: #afafaf;
  color: #000;
  padding: 10px 0.75rem;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 5px solid #000;
  vertical-align: middle !important;
  white-space: nowrap;
}

.rate-table.all-table thead tr th {
  border-bottom: 3px solid #ddd;
  border-left: 3px solid #ddd;
}

.shipperAddmodel {
  max-width: 1350px;
}

span.nav-item.homelabel {
  font-style: italic;
  font-size: 20px;
}

.InvalidKeyhomelabel {
  font-style: bold;
  font-size: 20px;
}

.mx-auto.frgtpwd {
  width: 354px;
  margin-top: 10rem;
}

.columnclass {
  background-color: white;
  padding: 1rem;
}

.form-group.btnresetpwd {
  float: right;
}

input.form-control.json-col.remarks {
  width: 71px;
}

ul#brokeravailableloads {
  margin-top: -20px;
}

#mapiconClass {
  font-size: 25px;
  color: var(--main-color);
  background-color: transparent;
}

table.table>tbody>tr>td {
  padding-left: 0.4rem;
}
.modalbodyclass {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.scroll-menu-arrow.scroll-menu-arrow--disabled {
  color: #ddd;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #c6c6c6 #e9ecef;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e9ecef;
}

*::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #c6c6c6;
  border-radius: 10px;
}

.ff {
  width: 100%;
  height: calc(100% - 42px);
  position: relative;
}

.accordion .spinner-border {
  display: block !important;
}

.modalbodyclass {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.Truck-list input[type="checkbox"] {
  background-color: #eee !important;
  position: relative;
}

.Truck-list input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 1px;
  background-color: #eee;
}

.Truck-list input[type="checkbox"]:checked {
  background-color: #000 !important;
  position: relative;
}

.Truck-list input[type="checkbox"]:checked::after {
  position: absolute;
  /* content: "\2714"; */
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 1px;
  background-color: var(--main-color);
  font-size: 14px !important;
  /* color: #FFBC00!important; */
}

i {
  border: 0;
  background-color: none;
  text-align: right;
  cursor: pointer;
}

.clearValueDisplay {
  display: none;
}

#documentDetails {
  font-size: 21px;
  color: red;
  background-color: transparent;
}

.factorheader {
  text-align: center;
  margin-top: -12px;
  text-transform: uppercase;
}

.carrierDropdown {
  padding: 3px;
  margin-top: 10px;
  /* padding-left: 632px; */
  text-align: center;
}

.dashboard-data .factor-breadcrump {
  bottom: 32px !important;
}

.eldliststyle {
  margin-top: -40px;
}

button.cobpdf {
  width: 25px;
  font-size: 16px;
  padding-top: 0;
  color: red;
}

input.json-col.dvirdata {
  margin-left: 75px;
}

label.confirmationboxclass {
  margin-left: 34px;
  margin-top: -21px;
  font-family: Roboto, "Segoe UI", Tahoma, sans-serif;
  font-size: 13px;
}

.icofonts {
  font-size: 18px;
  color: var(--main-color);
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}

.sketch-picker.ctrlShow {
  display: block;
}

.sketch-picker.ctrlHide {
  display: none;
}

#companyProf .logo-container {
  width: 100%;
  height: 140px;
}

#companyProf img.profileLogo {
  max-width: 200px;
  max-height: 140px;
  margin-bottom: 10px;
}

#trade-logo {
  width: 230px;
  height: 35px;
  object-fit: contain;
}

input[type="file"] {
  border: none !important;
  padding: 0 !important;
}

.login {
  width: 690px !important;
}

.login-logo {
  height: 120px;
  /* Can be anything */
  width: 100%;
  /* Can be anything */
  position: relative;
}

.login-logo img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.login-banner {
  margin-left: -4%;
  margin-right: -4%;
  height: 400px;
}

.login-banner img {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/*	google map */

.gm-style .gm-style-iw-t {
  position: absolute;
  width: 100%;
  bottom: 28px !important;
}

form.breadcrumbpopup .trim-margin>h4 {
  display: inline-block;
  margin: 5px 10px;
  width: 80%;
}

form.breadcrumbpopup .trim-margin>a {
  display: inline-block;
  width: 10%;
  margin-top: 10px;
}

.icofont-action-buttons {
  font-size: 18px;
  color: var(--main-color);
}

.page-error-text {
  font-size: 14px;
  color: red;
}

.page-error-text>a {
  color: red;
  text-decoration: underline !important;
}

img.profileFavicon {
  width: 16px;
  height: 16px;
}

.samsaraPrefix {
  position: absolute;
  top: 12px;
  left: 26px;
}

.newCol {
  position: relative;
  /* background-color: #3CC13B; */
}

.samasarlabelCssForVerifying {
  padding-left: 92px;
  display: inline-block;
  margin: 0.5rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 60%;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.labelCssForVerifying {
  display: inline-block;
  margin: 0.5rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 30%;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.labelCssForSearch {
  display: inline-block;
  margin-left: 0.5rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 60%;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

#iconPermissionTrue {
  font-size: 16px;
  color: var(--main-color);
  background-color: transparent;
  margin: 0 2px 0 2px;
}

#iconPermissionFalse {
  font-size: 16px;
  color: #C4C4C4;
  background-color: transparent;
  margin: 0 2px 0 2px;
}

#refreshBtn {
  color: white;
  background-color: var(--main-color);
  /* border-color: #bdfee0; */
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
  /* cursor: none; */
}

#refreshBtn:hover {
  color: var(--main-color);
  background-color: #fff;
  border-color: var(--main-color);
  cursor: pointer;
}

#refreshBtn:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px var(--main-color);
  /* Lowering the shadow */
}

#statusInvited {
  color: #3CC13B !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3CC13B;
  border-color: #3CC13B;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
  cursor: none;
  font-family: 'Poppins';
}

#statusInvited:hover {
  color: #fff;
  font-family: 'Poppins';
  background-color: var(--main-color);
  border-color: var(--main-color);
  cursor: pointer;
}

#statusInvited:hover {
  background-color: var(--main-color);
  color: #fff;
  border-color: #3CC13B;
  cursor: pointer;
  font-family: 'Poppins';
}

button .re-invite {
  display: none;
}

button:hover refresh {
  display: none;

}

button:hover .invite {
  display: none;
}

button:hover .re-invite {
  display: inline;
}

.dropdown-menu .userProfile {
  cursor: not-allowed;
  border: 0;
  color: grey;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;

}

#statusFactored {
  color: grey;
  background-color: #FFF3E1;
  border-color: #FFF3E1;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
  cursor: none;

}

#statusVerificationFailed {
  color: #F03738 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738;
  border-color: #F03738;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
  cursor: none;
  font-family: 'Poppins';
}

#statusNotInNetwork {
  color: grey;
  background-color: #ededed;
  border-color: lightgray;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
}

#statusInNetwork {
  color: grey;
  background-color: #c1e7ff;
  border-color: lightgray;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
}

#statusOnboarded {
  color: grey;
  background-color: lightskyblue;
  border-color: lightgray;
  box-shadow: none;
  outline: 0;
  border-radius: 50px;
}

#statusInNetwork:hover {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

#statusNotInNetwork:hover {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

#carrierNotInNetwork {
  color: #f5a623;
  background-color: #ededed;
  border-color: lightgray;
  box-shadow: none;
  text-decoration: underline;
  outline: 0;
  border-radius: 50px;
  padding: 0.3rem;
}

#carrierNotInNetwork:hover {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.view-carriers {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.search-carriers-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-carriers-img {
  margin-top: .75rem;
  height: 40px;
}

.verify-carriers-img {
  height: 195px;
  width: 256px;
  margin-left: 20px;
}

.mainDivcontainer {
  max-width: 1400px;
  margin-top: 20PX;
  margin-left: 16rem;
}

/*Prevent arrow keys from changing values in a number input*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
}

.sendbtn {
  float: right;
}

.closebtn {
  margin-left: 63%;
}

.addnewbtn {
  margin-left: 83%;
}

.okayButton {
  /* margin-top: 19rem; */
  margin-left: 9rem;

}

.form-check-label {
  font-size: 16px !important;
  line-height: 26px;
  margin-bottom: 10px;
}

.pointerenable {
  pointer-events: none !important;
  background-color: #efefef;
}

.infoIcon {
  border: 0;
  background-color: #0099ff;
  text-align: right;
  cursor: pointer;
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
  color: white;
}

.iconName {
  font-size: 17px;
}

.TitileCss {
  font-family: 'Poppins';
}

.subTitleCss {
  font-family: 'Poppins';
  font-weight: 600;
  height: 30px;
  font-size: 14px;
  padding-left: 1rem;
  margin-top: 1rem;
}

.welcomeCss {
  color: var(--main-color);
  font-size: 18px;
  font-family: 'Poppins';
}

.contentCss {
  font-family: 'Poppins';
}

.contentCss1 {
  color: #3CC13B;
  font-family: 'Poppins';
  font-size: 10px;
}

.contentCss2 {
  color: red;
  font-family: 'Poppins';
  font-size: 10px;
}

.contentCss3 {
  color: blue;
  font-family: 'Poppins';
}

/*progress bar css*/
.stepper-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
  margin-top: 10px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: var(--main-color);
  color: white;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--main-color);
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.stepper-item2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item2 .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  color: white;
  margin-bottom: 6px;
}

.stepper-item3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item3 .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  color: white;
  margin-bottom: 6px;
}

.stepper-item4 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item4 .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--main-color);
  color: white;
  margin-bottom: 6px;
}

/*ProgressBar CSS*/
info-container {
  width: 100%;
  text-align: center;
  margin-top: 15vh;
}

.progress-bar-container {
  /* height: 8px;
  width: 50vw;
  background-color: #e0e0de;
  border-radius: 50px; 
  margin: 6px auto 0 auto;
  margin-left: -1rem;
  margin-right: -1rem; */
  height: 14px;
  background-color: var(--main-color);
  border-radius: 50px;
  margin: 14px auto 0 auto;
  margin-left: -1rem;
  margin-right: -2rem;
  z-index: 10;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.progress-filler {
  height: 100%;
  border-radius: inherit;
  text-align: center;
  font-size: 10px;
}

.progress-label {
  padding: 0px;
  color: white;
  font-weight: bold;
  margin-left: .25rem;
}

.icofont-tick-mark {
  font-size: 7px;
}

.icofont-info-circle {
  font-size: 15px;
  color: var(--main-color);
}

/* Radio element, when checked */
/* input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
}
input[type='radio']:hover {
    box-shadow:0 0 2px 0px var(--main-color) inset;
}
input[type="radio"] {
  box-sizing: border-box;
  padding-top: 3.5px;
  padding-left: 2.5px;
}
   */

/*custom checkbox css*/
.checkBoxCss {
  box-sizing: border-box;
  transform: scale(1.3);
  pointer-events: none
}

.parentDivCss {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
}

.labelCss {
  margin-top: -3px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sidemenuIconCss {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.sidemenuTextCss {
  font-size: 12px !important;
}

.inviteBtn {
  display: flex;
  justify-content: flex-end;
}

.attemptCss {
  margin-left: 2rem;
}

.failed-attempt-css {
  padding: 0rem 0px 0px 1rem;
  width: 350px;
  color: red;
}

.failed-attempt-btn {
  width: 100px;
}

/*splash Screen Css*/
.lds-hourglass {
  display: flex;
  justify-content: center;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid var(--main-color);
  border-color: var(--main-color) transparent var(--main-color) transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

/*tooltip css*/
.__react_component_tooltip {
  background-color: white !important;
  color: var(--main-color) !important;
  font-weight: bold;
  border-radius: 1rem !important;
  border: 1px solid var(--main-color) !important;
  padding: 8px !important;
  z-index: 1200 !important;
}
.__react_component_tooltip.place-top::after {
  border-top: 6px solid var(--main-color) !important;
  z-index: 1200 !important;
}

/* Future Button Enhancement*/
/* .btn-primary:hover,
.btn-primary:active,.btn-primary:focus */
/* {
  background-color: white;
  box-shadow: 0px 2px 5px  var(--main-color) !important;
  color: var(--main-color) !important;
  transform: translateY(-3px);
  border: solid 1px var(--main-color) !important;
} */
/* .btn-primary:hover,
.btn-primary:active,.btn-primary:focus
{
  background-color:  var(--main-color);
  box-shadow: 0px 5px 8px rgba(46, 229, 157, 0.4);
  color: #fff !important;
  transform: translateY(-3px);
} */

.loaderButton {
  margin-right: -20px !important;
}

/* greed out bootstrap-table rows */
.custom-row-class {
  vertical-align: middle;
  opacity: 0.6;
  color: #44a4db;
}

.custom-row-class::after {
  content: 'carrier has disabled the data sharing';
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  color: #44a4db;
  text-align: center;
  border-radius: 1rem;
  padding: 5px 0;
  border: 1px solid #44a4db;
  padding: 8px;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 40%;
  overflow: hidden;
}

.custom-row-class:hover:after {
  opacity: 1;
  visibility: visible;
}

/* new instant COB css */
.parent-container {
  overflow-x: hidden;
  position: relative;
  padding-left: 1rem;
  /* border: 1px solid red; */
}

.onboard-left-side {
  background-color: #ffffff;
  padding: 0px;
  width: 24%;
  margin: 0px;
  position: fixed;
  top: -1rem;
  left: 0;
  bottom: 0;
  /* box-shadow: 13px 10px #f2e9e9fc; */
}

.onboard-right-side {
  background-color: #f6f6f6;
  width: 100%;
  position: relative;
  float: left;
  margin-left: 24%;
}

.left-row-after-img {
  margin-top: 3rem;
}

.left-row-img {
  margin-left: 2rem;
  margin-top: 2rem;
}

.cob-icon-css {
  font-size: '17px';
  font-weight: '400';
}

.eld-provider-text-css {
  padding: 1rem;
  font-family: 'Poppins';
  color: grey;
}

.list-company {
  margin-top: 3rem;
}

.info-icon-cob {
  color: var(--main-color);
  font-size: 'medium';
}

.search-elds {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchbar-css {
  display: flex;
  justify-content: space-around;
}

.eld-checkBox {
  margin-top: 20px;
}

.list-cont-data {
  margin-top: 4rem;
  padding-left: .5rem;
  word-break: break-all;
}

.eld-search-bar {
  justify-content: space-between;
  display: flex;
}

.eld-info-css {
  display: inline-block;
  margin-top: 10px;
  margin-left: 5px;
}

.eld-search-css {
  display: inline-block;
}

.form-check-header-data {
  padding-left: 2.5rem;
  display: inline-block;
  margin-bottom: 10px;
}

.search-container {
  max-width: 350px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background: #fff;
  margin: 10px;
}

.input-container,
.results-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 3px;
}

.input-container {
  justify-content: space-between;
  padding: 4px 10px;
  border-bottom: 2px solid #eee;
  width: 190px;
  max-width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background: #fff;
  margin-top: 10px;
  margin-left: 1rem;
}

.input-container1 {
  border-radius: 3px;
  float: right;
  margin-left: 16px;
}

.input-container input {
  padding: 4px 2px;
  box-sizing: border-box;
  border: none;
  flex-grow: 1;
  font-size: 1rem;
  outline: none;
}

.fa-search {
  font-size: 1.2rem;
  text-align: right;
  margin-left: 5px;
  cursor: pointer;
}

.results-container {
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.3s ease-out;
  max-height: 200px;
}

.results-container .result {
  text-align: center;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 15px 5px;
  cursor: pointer;
}

.results-container .result:hover {
  background-color: #f7f7f7;
}

.results-container .result:last-child {
  border-bottom: none;
}

.hide-results {
  max-height: 0;
}

.verify-img-css2 {
  /* width: 250px; */
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  float: right;
  /* margin-top: -3rem; */
}

.verify-img-css {
  width: 250px;
  align-items: flex-start;
}


.cob-link {
  color: #44a4db;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
}

.in-cob-button {
  margin-left: 1rem;
  display: block;
}

.attempt-css {
  padding: 0rem 0px 1rem 1rem;
}

.in-cob-modal {
  /* float:right; */
  margin-left: 1rem;
  /* padding: 1rem; */
}

.footer-buttons {
  display: flex;

}

.footer-button-signup {
  display: inline-block;
  width: 170px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 12rem;
  margin-bottom: .75rem;
}

.footer-button {
  display: inline-block;
  width: 170px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 13rem;
  margin-bottom: .75rem;
}

.footer-button2 {
  display: inline-block;
  width: 170px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 6rem;
  margin-bottom: .75rem;
}

.in-cob-footer-signup-button {
  position: fixed;
  bottom: 0;
  right: 30%;
  margin-bottom: 1rem;
}

.in-cob-footer-signup {
  position: fixed;
  padding: 0px;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  padding: 2.25rem;
  bottom: 0;
  width: 100%;
  height: auto;
  background: whitesmoke;
  display: flex;
  justify-content: center;
  border-top: solid 1px lightgray;

}

.in-cob-footer {
  position: fixed;
  padding: 0px;
  margin: 0px;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  height: auto;
  background: whitesmoke;
  display: flex;
  justify-content: flex-start;
  border-top: solid 1px lightgray;
  z-index: 20;
}

.samsaraPrefix {
  position: absolute;
  top: 5px;
  left: 20px;
}

.samasarlabelCssForVerifying {
  padding-left: 92px;
  display: inline-block;
  margin: 0px 0px 1rem 0px;
  width: 50%;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.logo-img-css {
  height: 50px;
}

.image {
  height: 60px;
  width: 120px;
  object-fit: contain;
  /* border: solid 1px #dee9ed; */
  padding: 3px;
  margin-left: 1rem;
}

.custom-td-eldlist {
  padding: 0px !important;
  padding-left: 0.75rem;
  font-size: 16px !important;
}

.custom-td-websitename {
  padding-left: .5rem;
  font-size: 16px !important;
}

/* Instant COB media queries starts */

@media only screen and (min-width: 600px) {
  .labelCssForVerifying {
    display: inline-block;
    margin: 0px 0px 1rem 0px;
    width: 30%;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .verify-img-css2 {
    height: 30px;
  }

  .samasarlabelCssForVerifying {
    padding-left: 92px;
    display: inline-block;
    margin: 0px 0px 1rem 0px;
    width: 30%;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  }
}

@media only screen and (max-width: 600px) {
  .left-row-after-img {
    margin-top: 2rem;
  }

  .left-row-img {
    padding: 0px;
    margin: 0px;
  }

  .list-cont-data {
    margin-top: 1rem;
    font-size: 12px;
  }

  .image {
    height: 70px;
    width: 100px;
    object-fit: contain;
    /* border: solid 1px #dee9ed; */
    padding: 3px;
  }

  .in-cob-img-css {
    text-align: center;
    height: 50px;
    margin-top: 1rem;
    box-shadow: 0px 4px 3px 3px rgb(225, 221, 221);
  }

  .onboard-right-side {
    padding: 0px;
  }

  .labelCssForVerifying {
    display: inline-block;
    margin: 0px 0px 1rem 0px;
    width: 50%;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  }

  .img_block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chkbox {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    margin-top: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
  }

  .table-data {
    padding: 0px;
  }

  .eld-search-bar {
    display: block;
  }
  .search-container {
    width: 100%;
    max-width: 500px;
  }

  .onboard-left-side {
    background-color: #ffffff;
    padding: 0px;
    margin: 0px;
    position: relative;
    width: 100%;

  }

  .onboard-right-side {
    background-color: #ffffff;
    position: relative;
    width: 100%;
    margin-left: 0%;
  }

  .eld-provider-text-css {
    padding: 1rem;
    /* background: #eeeeee; */
    color: grey;

  }

  /* Welcome Div CSS */
  .outer {
    border-radius: 45px;
    overflow: hidden;
    color: var(--main-color);
    border: solid 3px var(--main-color);
    margin-top: 2rem;
    margin-bottom: 3rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.eld-veify-btn {
  border-radius: '30px';
  cursor: 'not-allowed';
}

.data-share-btn {
  height: 45px;
}

/* logo-img-css */
@media only screen and (min-width: 750px) and (max-width: 950px) {
  .logo-img-css {
    width: 150px;
  }

  .outer {
    border-radius: 45px;
    overflow: hidden;
    color: var(--main-color);
    padding: 0px 20px;
    border: solid 3px var(--main-color);
    width: 70%;
    margin-left: 15%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 500px) {
  table.table>thead>tr>th {
    /* background-color: #eeeeee; */
    vertical-align: middle !important;
    border: 0;
    font-size: 12px;
    padding: 6px 6px;
  }

  .image {
    height: 70px;
    width: 100px;
    object-fit: contain;
    /* border: solid 1px #dee9ed; */
    padding: 3px;
  }

  .eld-provider-text-css {
    padding: 1rem;
    /* background: #eeeeee; */
    font-family: 'Poppins';
  }

  .form-check-header-data {
    padding-left: 2.5rem;
    display: block;
  }

  .subTitleCss {
    padding-left: 1.25rem;
  }

  .subTitleCss2 {
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 1rem;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1150px) {
  .footer-button {
    display: inline-block;
    width: 170px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    margin-left: 5rem;
    margin-bottom: .75rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .footer-button {
    display: inline-block;
    width: 110px;
    height: 45px;
    font-size: 9px;
    text-align: center;
    cursor: pointer;
    margin-left: 0.5rem;
    margin-bottom: .75rem;
  }

  .image {
    height: 70px;
    width: 100px;
    object-fit: contain;
    /* border: solid 1px #dee9ed; */
    padding: 3px;
  }

  .footer-button-signup {
    display: inline-block;
    width: 110px;
    height: 45px;
    font-size: 9px;
    text-align: center;
    cursor: pointer;
    margin-left: 0.6rem;
    margin-bottom: .75rem;
  }

  .eld-veify-btn {
    font-size: 12px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .footer-button {
    display: inline-block;
    width: 125px;
    height: 45px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: .75rem;
  }

  .image {
    height: 70px;
    width: 100px;
    object-fit: contain;
    /* border: solid 1px #dee9ed; */
    padding: 3px;
  }

  .footer-button-signup {
    display: inline-block;
    width: 110px;
    height: 45px;
    font-size: 9px;
    text-align: center;
    cursor: pointer;
    margin-left: 1.5rem;
    margin-bottom: .75rem;
  }

  .eld-veify-btn {
    font-size: 12px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .footer-button {
    display: inline-block;
    height: 40px;
    margin-left: .3rem;
    margin-bottom: .75rem;
  }

  .footer-button-signup {
    display: inline-block;
    margin-left: 6rem;
    height: 40px;
    margin-bottom: .75rem;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .footer-button {
    display: inline-block;
    margin-left: 1.2rem;
    height: 40px;
    margin-bottom: .75rem;
  }

  .footer-button-signup {
    display: inline-block;
    margin-left: 6rem;
    height: 40px;
    margin-bottom: .75rem;
  }
}

@media only screen and (min-width: 400px) and (max-width: 900px) {
  .image {
    height: 70px;
    width: 120px;
    object-fit: contain;
    /* border: solid 1px #dee9ed; */
    padding: 3px;
  }
}

/* Instant COB media queries ends */
#iconCOB {
  font-size: 16px;
  background-color: transparent;
  color: var(--main-color);
  margin: 5px;
}

.opt-heading-css {
  overflow: hidden;
  padding: 0rem;
  font-weight: 600;
  font-family: 'Poppins';
}

.website_name {
  display: flex;
  padding-top: .75rem;
}

/* sweepstakes ui css*/
.sweepstakes-button-css {
  color: #fff !important;
  background-color: #3d3f4c;
  box-shadow: none;
  outline: 0;
  font-family: 'Poppins';
  letter-spacing: 3px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 15px;
}

.sweepstakes-button-css:hover {
  color: #fff !important;
  background-color: #3d3f4c;
  border-color: #3d3f4c;
  box-shadow: none;
  outline: 0;
  font-family: 'Poppins';
}

.para-css {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 700;
  margin-top: 10px;
}

.win-chance-heading-css {
  font-size: 50px;
  font-family: 'Poppins';
  font-weight: 700;
  text-align: center;
}

.join-heading-css {
  font-size: 50px;
  font-family: 'Poppins';
}

.sweepstakes-error {
  font-size: 20px;
}

.caption-button-css {
  top: 40%;
}

.img-crsl-css {
  height: 100vh;
  opacity: 0.1;
  background-position: 50% 50%;
}

.bg-transparent {
  opacity: 0.2;
}

.caption2Css {
  color: black;
  top: 33%;
  bottom: auto;
}

.caption3Css {
  color: black;
  top: 1rem;
  bottom: auto;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.7;
}

.sweep-heading-css {
  font-size: 40px;
  font-family: 'Poppins';
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 3rem;
  color: #3d3f4c;
}

.sweep-heading2-css {
  font-size: 39px;
  font-family: 'Poppins';
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 3rem;
  color: #3d3f4c;
}

.sweep-heading3-css {
  font-size: 36px;
  font-family: 'Poppins';
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 3rem;
  color: #3d3f4c;
}

.head1-css {
  font-family: 'Poppins';
  text-align: left;
  color: #3d3f4c;
}

.para2-css {
  font-size: 20px;
  font-family: 'Poppins';
  text-align: left;
  color: #3d3f4c;
}

.sweep-sub-heading-css {
  font-size: 20px;
  font-family: 'Poppins';
  color: #3d3f4c;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.svgImgCss {
  color: rgba(68, 163, 219, 1);
  border-color: rgba(35, 157, 219, 0);
  background-color: rgba(189, 225, 244, 0);
  background-image: none;
  border-width: 0;
  border-style: solid;
  width: 58px;
  height: 58px;
  font-size: 518px;
  padding: 0px;
  border-radius: 5px;
  stroke-width: 2.3;
}

.trade-logo-sweepstakes {
  height: 50px;
}

.FAQ-headerbar {
  padding: 1rem;
  padding-left: 19rem;
}

.faq-sweepstakes {
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('https://truckercloud.com/wp-content/uploads/2021/03/jahongir-ismoilov-RcjvFd1k18o-unsplash-scaled.jpg');
  height: 230vh;
}

.sweepstakes-headerbar {
  padding-bottom: 1rem;
  padding-left: 15rem;
  top: 0;
  background-color: white;
}

.animate-charcter {
  /* background-image: linear-gradient(
    -225deg,
    rgba(68, 163, 219, 1) 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );*/
  background-color: rgb(61, 63, 76);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #0099ff;
  ;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: splash 2s normal forwards ease-in-out;
}

@keyframes splash {
  0% {
    color: black;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.carousel-control-next,
.carousel-control-prev

/*, .carousel-indicators */
  {
  filter: invert(100%);
}

ol.carousel-indicators {
  position: absolute;
  bottom: 5px;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
}

ol.carousel-indicators li,
ol.carousel-indicators li.active {
  width: .5rem;
  height: .5rem;
  margin: 0;
  border-radius: 50%;
  border: 0;
  background: transparent;
}

ol.carousel-indicators li {
  background: transparent;
  border: 3px solid black;
  margin-left: .5rem;
  margin-right: .5rem;
}

ol.carousel-indicators li.active {
  border: 3px solid black;
  background: black;
  margin-left: .5rem;
  margin-right: .5rem;
}

.flex-parent {
  display: flex;
  padding-bottom: 5rem;
}

.jc-center {
  justify-content: center;
}

.faq-button {
  margin-left: 1rem;
  background-color: rgba(68, 163, 219, 1);
}

.faq-button:hover {
  color: #fff !important;
  background-color: #3d3f4c;
  border-color: #3d3f4c;
  box-shadow: none;
  outline: 0;
  font-family: 'Poppins';
}

.card-css {
  margin-top: 25px;
}

.card-grid:hover {
  background-color: transparent;
  color: #000;
}

.card-grid {
  margin: 5px;
  padding: 25px 20px 25px 20px;
  height: 37rem;
}

.remove-white-space-css {
  height: auto;
  /* position: relative; */
  /* top: -1700px; */
}

/*toggle css */
.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #44a3db;
}

input:focus+.slider {
  box-shadow: 0 0 1px #44a3db;
}

input:checked+.slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.opt-heading-css {
  overflow: hidden;
  padding: 0rem;
  font-family: 'Poppins';
}

.opt-sub-heading-css {
  overflow: hidden;
  padding: 0rem;
  font-family: 'Poppins';
  font-size: 14px;
  padding-right: 1rem;
}

.opt-sub-heading-css2 {
  overflow: hidden;
  padding: 0rem;
  font-family: 'Poppins';
  font-size: 14px;
  float: right;
  padding-right: 2.75rem;
}

.opt-sub-heading-css3 {
  overflow: hidden;
  padding: 0rem;
  font-family: 'Poppins';
  font-size: 14px;
  float: right;
}

/* sweeps take UI ends here */

/* widget css starts  */
.modal-body-css {
  padding: 0rem;
}

.modal-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px;
}

.modal-row2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 1rem;
}

.lastname-css {
  margin-left: 5.5rem;
}

.modal-header2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.redirection-timer {
  font-size: 18px;
  font-family: 'Poppins';
  color: var(--main-color);
}

.requiredfield-key {
  color: red;
}

/* widget css ends  */

/* Factoring css */
.matched {
  box-sizing: border-box;
  /* Auto layout */
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3CC13B;
  border-radius: 32px;
  color: #3CC13B;
  border: solid 1px #3CC13B;
}

.unmatched {
  box-sizing: border-box;
  /* Auto layout */
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738;
  border-radius: 32px;
  color: #F03738;
  border: solid 1px #F03738;
}

.card-css-factor {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 200px;
  left: 2.25rem;
  top: 1.1rem;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 4px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.viewbtn {
  margin-left: 40%;
  margin-top: 1rem;
}

/* sidemenu create css */
.sidebar-menu {
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 70%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: lightblue;
  z-index: 1;
  font-family: 'Poppins';
}

.sidebar-menu.open {
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
  font-family: 'Poppins';
}

.sidebar-menu .menu>li>a {
  padding: 15px 20px;
  color: #fff;
  font-family: 'Poppins';
}

.wrapper2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin: 1rem;
  width: 375px;
  padding: 1.5rem;
  height: 150px;
  left: 2.25rem;
  top: 1.1rem;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 4px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.cardIconCss {
  color: var(--main-color);
  font-size: x-large;
  margin-bottom: 10px;
}

/* Branding color code css */
.colorCodeDiv {
  display: flex;
}

.colorCodeSpan {
  border: solid 1px ghostwhite;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.PreviewDiv {
  display: contents;
  justify-content: space-around;
  margin-top: 1rem;
}

.sketch-picker {
  margin-top: 5px;
}

.loaderDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.CircularProgressCSS {
  color: var(--main-color) !important;
}

.h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Poppins';
}

/* Material UI css */
.collapse:not(.show) {
  margin-left: 5rem !important;
  z-index: 1209 !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: initial;
}

.MuiListItemButton-root.Mui-selected {
  background-color: rgba(25, 118, 210, 0.08);
  border-left: 5px solid var(--main-color) !important;
}

.MuiTypography-root {
  font-family: 'Poppins' !important;
}
.tab-btn {
  margin-bottom: -1rem;
}
.dataSharetext {
  color: #3CC13B;
}
.dataShareBtn {
  color: #3CC13B;
  margin-left: -10px;
}
.dataShareBtn2 {
  color: #3CC13B;
  font-size: 15px;
  margin-left: 10px;
}
/* New instant COB CSS */
/* New Instant COB */
.container-eld {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 704px;
  height: 72px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo1 {
  /* background-color: #0099ff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 320px;
  height: 72px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo2 {
  width: 32px;
  height: 32px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.sudologo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  display: none;
  width: 24px;
  height: 48px;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.logo3 {
  /* background-color: #3CC13B; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:flex-start;
  padding: 16px;
  gap: 10px;
  width: 320px;
  height: 72px;
  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
}

.permBtm {
  padding: 1rem;
  margin-left: 1rem;
  width: 248px;
  float: right;
  /* Button */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  /* Primary/Contrast */
  color: var(--main-color);
}
.addAnotherELD {
  padding: 1rem;
  margin-left: 1rem;
  width: 178px;
  float: right;
  /* Button */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  /* Primary/Contrast */
  color: var(--main-color);
}
.backBtn {
  width: 125px;
  padding: 1rem;
}

.authbtn {
  float: right;
  padding: 1rem;
  /* width: 125px; */
  /* Button */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  /* Primary/Contrast */
  color: #FFFFFF;
  margin-left: 16px;
}
.authbtn2 {
  float: right;
  padding: .5rem;
  width: 125px;
  /* Button */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  /* Primary/Contrast */
  color: var(--main-color);
  margin-left: 16px;
}
.newCol2 {
  position: relative;
}

.permission-left-side-data {
  padding: 0;
  margin: 0;
}

.checkbox-lg .form-check-input {
  top: -0.5rem;
  scale: 1.7;
  margin-right: 0.7rem;
}

.checkbox-lg2 .form-check-input {
  margin-top: 2rem;
  scale: 1.7;
}

.checkbox-lg3 .form-check-input {
  scale: 1.4;
}
.verifyImgDivCss {
  display: flex;
  justify-content: center;
}
.eld-search-bar-mui {
  display: flex;
  float: right;
  margin-right: 3rem;
  margin-bottom: 1rem;
}
.custom-row-class-eldlist {
  padding-left: 1rem;
  padding-right: 2rem;
  height: 100%;
  font-size: 16px !important;
  overflow: hidden;
  overflow-y: scroll;
  /* padding-bottom: 5rem; */
}
.eld-mat-veify-btn {
  width: 170px;
  margin-left: 82%;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
  padding: .75rem;
}
.website_anchor {
  color: var(--main-color) !important;
}
.eldboxClassName {
  margin-right: 4rem;
}
.img_block {
  display: flex;
  align-items: center;
}
.eldImage {
  height: 60px;
  width: 105px;
  object-fit: contain;
}
.newELDBTN {
  padding: .75rem;
  margin-top: -.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.3px;
  /* Primary/Contrast */
  color: #FFFFFF;
}
.samsaraPrefix2 {
  position: absolute;
  top: 6px;
  left: 15px;
  height: 40px;
  font-size:16px;
}
.samasarlabelCssForVerifying2 {
  width: 45%;
  /* padding-left: 120px; */
  display: inline-block;
  margin: 0px 0px 1rem 0px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.samasarlabelCssForVerifying3{
  width: 45%;
  padding-left: 120px;
  display: inline-block;
  margin: 0px 0px 1rem 0px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


input[type=checkbox] {
  /* accent-color: var(--main-color); */
}

.listDivCss {
  /* background-color: #3CC13B; */
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;
  /* width: 287px;
  height: 60px;
  margin-top: 16px;
  margin-left: 10px; */
}
.eld-btn-ftr{
  margin-top: 35px;
}
.MuiListItemIcon-root {
  min-width: 0px !important;
}
.form-input2{
  height: 40px;
  font-size:16px;
}
.headerAction{
  float: right;
}
.shadow-input2 {
  color: red !important;
  font-size: 1rem;
  font-weight: normal;
  display: block !important;
  margin: 10px !important
}